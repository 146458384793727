<template>
  <LayoutMainPages>
    <section class="trade-container">
      <div class="page-inner">
        <div class="display-and-info">
          <div class="asset-viewer">
            <AssetDisplay
              @onGoBack="goBack"
              @onShareEvent="emitShareEvent"
              @onToggleFavorite="toggleFavorite"
              :player="lodashGet(asset, 'base.associated_players[0].name', '')"
              :imgBg="lodashGet(asset, 'base.img_front', '')"
              :imgFront="lodashGet(asset, 'base.img_front', '')"
              :imgBack="lodashGet(asset, 'base.img_back', '')"
              :individual-assets="lodashGet(asset, 'base.individual_assets', null)"
              :isLoading="isSkeletonLoading"
              :isOnFavoritesList="isOnFavoritesList"
              show-favorites-button
            />
          </div>

          <div class="side-panel">
            <AssetInfo
              @onShareEvent="emitShareEvent"
              @onToggleFavorite="toggleFavorite"
              :asset="asset"
              :isLoading="isSkeletonLoading"
              :isOnFavoritesList="isOnFavoritesList"
            />

            <div class="trade-actions">
              <SkeletonLoading
                v-if="isSkeletonLoading"
                borderRadius="24px"
                height="44px"
                width="116px"
              />
              <ButtonV2
                v-else
                @onClick="openTradeWidget('buy')"
                :label="$tc('asset_detail.buy_now')"
                size="medium"
                version="primary"
                :wide="isMediumMobileDevice"
              />
              <SkeletonLoading
                v-if="isSkeletonLoading"
                class="ml-s20"
                borderRadius="24px"
                height="44px"
                width="113px"
              />
              <ButtonV2
                v-else-if="hasOwnership"
                @onClick="openTradeWidget('sell')"
                class="ml-s20"
                :label="$tc('asset_detail.sell_now')"
                size="medium"
                version="secondary"
                :wide="isMediumMobileDevice"
              />
            </div>

            <TradePriceHistory
              @onUpdateChartRange="updateChartRange"
              class="mt-s48"
              :asset="asset"
              :isLoading="isSkeletonLoading"
            />

            <TradeBalance
              class="mt-s48"
              :asset="asset"
              :isLoading="isSkeletonLoading"
            />

            <TradeCardLadder
              class="mt-s48"
              :cardladder="lodashGet(asset, 'base.cardladder', {})"
              :isLoading="isSkeletonLoading"
            />

            <TradeSerialNumbers
              class="mt-s48"
              :address="getUserIdentificationSummary.address"
              :cardName="lodashGet(asset, 'base.name')"
              :email="lodashGet(currentUser, 'user_info.email')"
              :individualAssets="lodashGet(asset, 'base.individual_assets')"
              :isLoading="isSkeletonLoading"
              :isUserAuthenticated="isUserAuthenticated"
              :marketCap="lodashGet(asset, 'market_cap', 0)"
              :supply="lodashGet(asset, 'base.supply', '')"
              :userName="getUserIdentificationSummary.name"
            />

            <div class="grid grid-cols-2 gap-s32 mt-s48 lg:grid-cols-1">
              <TradeOrderbook
                v-if="!isSkeletonLoading"
                :lastPrice="lodashGet(asset, 'price_per_token', 0)"
                :orderbook="lodashGet(asset, 'orderbook', {})"
              />
              <TradeRecentOrders
                v-if="!isSkeletonLoading"
                class="lg:mt-s24"
                :orders="recentTrades || new Array(12).fill(0)"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </LayoutMainPages>
</template>

<script>
import { mapGetters } from 'vuex';
import { AssetDisplay, AssetInfo } from '@/modules/asset';
import { TradeBalance, TradeCardLadder, TradePriceHistory, TradeOrderbook, TradeRecentOrders, TradeSerialNumbers } from '@/modules/trade';

import { ButtonV2, LayoutMainPages } from '@/components/misc';

import TRADE from '@/graphql/queries/Trade.gql';
import WATCHLIST_ADD_ASSET from '@/graphql/mutations/WatchlistAddAsset.gql';
import WATCHLIST_REMOVE_ASSET from '@/graphql/mutations/WatchlistRemoveAsset.gql';

export default {
  name: 'Trade',
  
  components: {
    AssetDisplay,
    AssetInfo,
    ButtonV2,
    LayoutMainPages,
    TradeBalance,
    TradeCardLadder,
    TradePriceHistory,
    TradeOrderbook,
    TradeRecentOrders,
    TradeSerialNumbers,
  },
  data() {
    return {
      chartRange: null,
      isOnFavoritesList: false,
      isSkeletonLoading: true,
    };
  },
  computed: {
    ...mapGetters('api', [
      'getUserIdentificationSummary'
    ]),
    ...mapGetters('asset', [
      'getCurrentPair'
    ]),

    action() {
      return this.$route.params.action;
    },

    asset() {
      return this.$store.state.api['pair'] || {};
    },

    assetSpecs() {
      return this.asset.base.specs.reduce((accumulatedSpecs, spec) => ({
        ...accumulatedSpecs,
        [`asset_${spec.key}`]: spec.value
      }), {});
    },

    assetEventVariables() {
      return {
        ...this.assetSpecs,
        id: this.asset.id,
        id_hr: this.asset.id_hr,
        base_name: this.asset.base.name,
        base_ticker: this.asset.base.ticker,
        quote_name: this.asset.quote.name,
        quote_ticker: this.asset.quote.ticker,
        price: this.asset.price_per_token,
        base_supply: this.asset.base.supply,
        price_change_24h: this.asset.price_change_24h,
        volume_base_change_24h: this.asset.volume_base_24h,
        volume_quote_change_24h: this.asset.volume_quote_24h,
        market_cap: this.asset.market_cap,
        player_id: this.asset.base.associated_players[0].id,
        player_id_hr: this.asset.base.associated_players[0].id_hr,
      };
    },

    currentUser() {
      return this.$store.state.api['current_user'] || {};
    },

    hasOwnership() {
      return this.$big(this.lodashGet(this.asset, 'base.current_user_amount', 0)).gt(0);
    },

    isUserAuthenticated(){
      return this.$store.state.api.isAuthenticated;
    },

    recentTrades() {
      return this.asset.recent_trades.map(trade => {
        return {
          ...trade,
          price: trade.price,
          amount: trade.amount,
        };
      });
    },
  },
  methods: {
    checkFavorite() {
      const watchListsThisAssetIsOn = this.lodashGet(this.asset, 'base.current_user_watch_lists', []);
      this.isOnFavoritesList = watchListsThisAssetIsOn.some(watchlist => watchlist.name === 'favorites');
    },

    async emitAssetEvent(event) {
      await this.$store.dispatch('events/track', {
        event,
        variables: {
          ...this.assetEventVariables,
        },
      });
    },

    async emitShareEvent() {
      await this.emitAssetEvent('ASSET_SHARED');
    },

    goBack() {
      this.$router.go(-1);
    },

    async loadData() {
      this.isLoading = true;

      await this.$store.dispatch('api/refreshPageData', {
        $apollo: this.$apollo,
        query: TRADE,
        variables: {
          pair: this.$route.params.pair,
          chartEndTimeSeconds: new Date().getTime() / 1000,
          orderbookDecimals: -10,
          window: this.chartRange.window,
          resolution: this.chartRange.resolution,
        },
      });

      this.isLoading = false;
    },

    async loaded() {
      if (this.lodashIsEmpty(this.asset)) {
        await this.showError(new Error('unknown_pair'));
        return this.goToInternalPageGlobal('/');
      }

      this.isSkeletonLoading = false;

      this.checkFavorite();
      await this.emitAssetEvent('ASSET_VIEWED');

      if (this.action === 'buy' || (this.action === 'sell' && this.hasOwnership)) {
        this.openTradeWidget(this.action);
      }
    },

    openTradeWidget(mode) {
      this.showModal('TradeWidget', {
        asset: this.asset,
        chartRange: this.chartRange,
        initialMode: `${mode}_card`,
      });
    },

    routerToLogin(){
      return this.goToInternalPageGlobal('/login');
    },

    async toggleFavorite() {
      if (!this.isUserAuthenticated) {
        return this.routerToLogin();
      }
      // Zero delay approach
      this.isOnFavoritesList = !this.isOnFavoritesList;
      await this.showSuccess(this.$t(`watchlist.${this.isOnFavoritesList ? 'saved' : 'removed'}`));

      // Actual api call
      try {
        await this.apolloApiCall({
          mutation: this.isOnFavoritesList ? WATCHLIST_ADD_ASSET : WATCHLIST_REMOVE_ASSET,
          variables: {
            asset_id: this.asset.base.id,
            list_name: 'favorites'
          },
        });
        await this.loadData();
      } catch (err) {
        await this.showError(err);
        this.isOnFavoritesList = !this.isOnFavoritesList;
      }
    },

    async updateChartRange(option) {
      this.chartRange = option;

      await this.$store.dispatch('events/track', {
        event: 'ASSET_CHART_TIMEFRAME_CHANGED',
        variables: {
          ...this.assetEventVariables,
          timeframe: option.text,
        },
      });
    },
  },
  
  watch: {
    globalIsLoading(newValue, oldValue) {
      if (oldValue || !newValue) {
        this.$nextTick(() => {
          this.loaded();
        });
      }
    },
  },
  mounted() {
    if (!this.globalIsLoading) {
      this.loaded();
    }
  },
};
</script>

<style scoped>
.trade-container {
  @apply flex flex-row justify-center w-full;
}
.page-inner {
  @apply flex flex-col w-full py-s40;
  max-width: 1242px;
}
.display-and-info {
  @apply flex flex-row;
}
.asset-viewer {
  @apply flex-1 flex-grow;
  min-width: 400px;
  max-width: 580px;
}
.side-panel {
  @apply w-full ml-s40 box-border;
  max-width: 580px;
}
.trade-actions {
  @apply flex flex-row mt-s40;
}

@screen lg {
  .page-inner {
    @apply flex-col pt-0;
    max-width: 100vw;
    margin-bottom: 85px;
  }
  .display-and-info {
    @apply flex-col justify-center;     
  }
  .asset-viewer {
    min-width: 0;
    max-width: 100vw;
    height: 520px;
  }
  .side-panel {
    @apply px-s20 ml-s0 max-w-full;
  }
  .side-panel .button-v2 {
    min-width: auto;
  }
  .trade-actions {
    @apply
      w-full
      p-s20
      mt-s0
      bg-background-page
      z-component
      fixed
      bottom-0
      left-0
    ;
    height: 85px;
  }
}
</style>
